(function () {
  try {
    let inputs = document.querySelectorAll("[data-ru-field]");

    function rusLang(text) {
      return /^[а-яёА-ЯЁ\s]+$/.test(text);
    }

    inputs.forEach((input) => {
      input.addEventListener("input", function () {
        if (!rusLang(input.value)) {
          input.value = input.value.replace(/[^\а-яёА-ЯЁ\s-]/g, "");
        }
      });
    });

    let nums = document.querySelectorAll("[data-num-field]");

    function isDigitsOnly(text) {
      return /^[0-9\s]+$/.test(text);
    }

    nums.forEach((input) => {
      input.addEventListener("input", function () {
        if (!isDigitsOnly(input.value)) {
          input.value = input.value.replace(/[^\d\s-]/g, ""); // удаляет все символы, кроме цифр и пробелов
        }
      });
    });

    // Функция для проверки ввода только английских букв, цифр и разрешенных символов
    let engInputs = document.querySelectorAll("[data-eng-field]");

    function isEnglish(text) {
      return /^[a-zA-Z0-9@\.\-_]+$/.test(text);
    }

    engInputs.forEach((input) => {
      input.addEventListener("input", function () {
        if (!isEnglish(input.value)) {
          input.value = input.value.replace(/[^\a-zA-Z0-9@\.\-_]/g, "");
        }
      });
    });


  } catch (error) {}
})();
