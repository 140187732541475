export const cartPromocode = () => {

    
    const cartPromocode = document.querySelector('.js-cart-promocode');

    if ( cartPromocode ) {
        
        let clear = cartPromocode.querySelector(".js-cart-promocode-clear");
        let empty = cartPromocode.querySelector(".js-cart-promocode-empty");
        let used = cartPromocode.querySelector(".js-cart-promocode-used");

        if ( clear && empty && used ) {

            clear.addEventListener('click',function(){
                empty.classList.remove("display-none");
                used.classList.add("display-none");
                clear.classList.add("display-none");
            });

        }

    }


}