export const productCard = () => {

    const sizesOpenBtns = document.querySelectorAll('.js-sizes-open');
    const sizesCloseBtns = document.querySelectorAll('.js-sizes-close');

    if ( sizesOpenBtns.length ) {
        for (let btn of sizesOpenBtns ) {
            btn.addEventListener('click',function(){
                btn.closest(".block-catalog__card").querySelector(".block-catalog__sizes").classList.add("block-catalog__sizes_open");
            });
        }
    }

    if ( sizesCloseBtns.length ) {
        for (let btn of sizesCloseBtns ) {
            btn.addEventListener('click',function(){
                btn.closest(".block-catalog__sizes").classList.remove("block-catalog__sizes_open");
            });
        }
    }

}