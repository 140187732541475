export const filters = () => {

    const filtersClear = document.querySelector('.js-filters-clear');

    if ( filtersClear ) {
        filtersClear.addEventListener('click',function(){
            let form = filtersClear.closest("form");
            if ( form ) {
                form.reset();
                let selects = form.querySelectorAll(".select");
                if ( selects.length ) {
                    for (let select of selects ) {
                        //select.querySelector("input").setAttribute("value", "");
                        select.querySelector("input").removeAttribute("data-full", "");
                        select.querySelector(".select__value").removeAttribute("data-full", "");
                        select.querySelector(".select__value").innerText = select.querySelector(".select__value").dataset.placeholder;
                        select.querySelector(".select__option_selected")?.classList.remove("select__option_selected");
                    }
                }
            }
        });
    }

}