export const message = () => {

    const messageClose = document.querySelectorAll('.js-message-close');

    if ( messageClose.length ) {
        for (let close of messageClose ) {
            close.addEventListener('click',function(){
                close.closest(".js-message").classList.add("display-none");
            });
        }
    }

}