export const mobileMenu = () => {

    const mobileMenu = document.querySelector('.mobile-menu');
    const mobileMenuOpen = document.querySelector('.js-mobile-menu-open');
    const subMobileMenu = document.querySelector('.mobile-menu__submenu');
    const subMobileMenuOpen = document.querySelectorAll('.js-mobile-menu-submenu-open');
    const subMobileMenuClose = document.querySelector('.js-mobile-menu-submenu-close');
    var distance = 0;

    function disable_scroll() {
        document.querySelector(".site-container").classList.add("dis-scroll");
        document.querySelector(".site-container").scrollTo({
          top: distance,
          left: 0,
        });
    }

    if ( mobileMenuOpen && mobileMenu ) {
        mobileMenuOpen.addEventListener('click',function(){
            mobileMenuOpen.classList.toggle("header__burger_open");
            mobileMenu.classList.toggle("mobile-menu_open");
            
            
            if ( mobileMenu.classList.contains("mobile-menu_open") ) {
                distance = window.pageYOffset;
                disable_scroll();
            } else {
                document.querySelector(".site-container").classList.remove("dis-scroll");
                setTimeout(() => {
                    window.scrollTo({
                        top: distance,
                        left: 0,
                    });
                }, 5);
            }
        });
    }

    if ( subMobileMenuOpen.length && subMobileMenu ) {
        for (let link of subMobileMenuOpen ) {
            link.addEventListener('click',function(e){
                e.preventDefault();
                let title = link.innerText;
                let data = link.dataset.menu;
                let submenuLinks = subMobileMenu.querySelectorAll(".mobile-menu__link");
                if ( submenuLinks.length ) {
                    for (let sublink of submenuLinks ) {
                        sublink.parentElement.style.display = 'none';
                        if ( sublink.dataset.menu == data ){
                            sublink.parentElement.style.display = 'block';
                        }
                    }
                }
                subMobileMenu.querySelector(".mobile-menu__back-text").innerText = title;
                subMobileMenu.classList.add("mobile-menu__submenu_open");
            });
        }
    }

    if ( subMobileMenuClose && subMobileMenu ) {
        subMobileMenuClose.addEventListener('click',function(e){
            e.preventDefault();
            subMobileMenu.classList.remove("mobile-menu__submenu_open");
        });
    }



}