

export const sliders = () => {

    
    
    const rangeSliderWraps = document.querySelectorAll('.js-range-slider-wrap');
 
    
    const singleSlider = document.querySelector('.js-single-slider');
    const loyaltySaleBlocks = document.querySelectorAll('.js-loyalty-sale-block');
    const filtersClear = document.querySelectorAll('.js-modal-filters-clear');


    
    if ( rangeSliderWraps.length ) {
        
        for (let wrap of rangeSliderWraps ) {

            let rangeSlider = wrap.querySelector('.js-range-slider');
            let rangeSliderPriceMin = wrap.querySelector('.js-range-slider-price-min');
            let rangeSliderPriceMax = wrap.querySelector('.js-range-slider-price-max');
            let sliderClear = wrap.querySelector('.js-range-slider-clear');

            if ( rangeSlider && rangeSliderPriceMin && rangeSliderPriceMax ) {
        
                let sliderOne = rangeSlider.querySelectorAll('input')[0];
                let sliderTwo = rangeSlider.querySelectorAll('input')[1];
                let sliderTrack = rangeSlider.querySelector(".range-slider__track");
                let sliderMinValue = sliderOne.min;
                let sliderMaxValue = sliderTwo.max;
                let minGap = 0;
        
                slideOne();
                slideTwo();

                sliderOne.oninput = function() {
                    slideOne();
                }
                sliderTwo.oninput = function() {
                    slideTwo();
                }
        
                function slideOne(){
                    if(parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap){
                        sliderOne.value = parseInt(sliderTwo.value) - minGap;
                    }
                    rangeSliderPriceMin.value = sliderOne.value + " руб.";
                    fillColor();
                }
        
                function slideTwo(){
                    if(parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap){
                        sliderTwo.value = parseInt(sliderOne.value) + minGap;
                    }
                    rangeSliderPriceMax.value = sliderTwo.value + " руб.";
                    fillColor();
                }
        
                function fillColor(){
                    let percent1 = ( (sliderOne.value - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;
                    let percent2 = ( (sliderTwo.value - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;
                    sliderTrack.style.background = `linear-gradient(to right, #1E1E1E1A ${percent1}% , #1E1E1E ${percent1}% , #1E1E1E ${percent2}%, #1E1E1E1A ${percent2}%)`;
                }
        
                rangeSliderPriceMin.onchange = function() {
                    sliderOne.value = this.value;
                    slideOne();
                };
                rangeSliderPriceMax.onchange = function() {
                    sliderTwo.value = this.value;
                    slideTwo();
                };
        
                let rangeSliderPriceMinFocus = 0;
                rangeSliderPriceMin.onfocus = function() {
                    if ( rangeSliderPriceMinFocus == 0 ) {
                        this.value = this.value.replace(' руб.','');
                    }
                    rangeSliderPriceMinFocus = 1;
                };
                rangeSliderPriceMin.onblur = function() {
                    rangeSliderPriceMinFocus = 0
                    if ( this.value.indexOf(" руб.") == -1 ) {
                        this.value = this.value + " руб.";
                    }
                };
        
                let rangeSliderPriceMaxFocus = 0;
                rangeSliderPriceMax.onfocus = function() {
                    if ( rangeSliderPriceMaxFocus == 0 ) {
                        this.value = this.value.replace(' руб.','');
                    }
                    rangeSliderPriceMaxFocus = 1;
                };
                rangeSliderPriceMax.onblur = function() {
                    rangeSliderPriceMaxFocus = 0
                    if ( this.value.indexOf(" руб.") == -1 ) {
                        this.value = this.value + " руб.";
                    }
                };
        
                if ( sliderClear ) {
                    sliderClear.addEventListener('click',function(){
                        sliderOne.value = 1099;
                        sliderTwo.value = 3599;
                        slideOne();
                        slideTwo();
                    });
                }
        
                if ( filtersClear.length ) {
        
                    for (let clear of filtersClear ) {
                        clear.addEventListener('click',function(){
                            sliderOne.value = 1099;
                            sliderTwo.value = 3599;
                            slideOne();
                            slideTwo();
            
                            let selects = document.querySelector('.modal[data-modal=filters]').querySelector(".modal__selects").querySelectorAll(".select");
        
                            if ( document.querySelector('.block-catalog__filters-clear') ) {
                                document.querySelector('.block-catalog__filters-clear').style.display = "none";
                                document.querySelector('.block-catalog__filters-btn')?.classList.remove("block-catalog__filters-btn_active");
                            }
            
                            if ( selects.length ) {
                                for (let select of selects ) {
            
                                    let value = select.querySelector(".select__value");
                                    let placeholder = value.dataset.placeholder;
                                    let checks = select.querySelector(".select__list").querySelectorAll("input");
            
                                    value.removeAttribute("data-full");
                                    value.innerHTML = placeholder;
            
                                    for (let check of checks ) {
                                        check.checked = false;
                                    }
            
                                }
                            }
            
                        });
                    }
        
                    
                }
        
            }

        }

    }

    
    

    

    if ( singleSlider ) {
        let sliderTrack = singleSlider.querySelector(".js-single-slider-track");
        let sliderOne = singleSlider.querySelectorAll('input')[0];
        let sliderScale = singleSlider.querySelector(".js-single-slider-scale");

        fillColor();
        setSale();

        sliderOne.oninput = function() {
            fillColor();

            if ( sliderScale ) {
                document.querySelector('.loyalty__sale-step_active')?.classList.remove("loyalty__sale-step_active");
                let index = sliderOne.value / 50;
                let step = sliderScale.querySelectorAll("div")[index];
                step.classList.add("loyalty__sale-step_active");

                let dataSale = step.dataset.sale;
                let dataPrice = step.dataset.price;
                
                sliderOne.setAttribute("data-price", dataPrice);
                sliderOne.setAttribute("data-sale", dataSale);

            }

            setSale();

        }

        function fillColor() {
            let percent1 = sliderOne.value * 1;
            sliderTrack.style.background = `linear-gradient(to right, #669887 ${percent1}% , #e2e2e2 ${percent1}%`;
        }

        function setSale() {
            if ( loyaltySaleBlocks.length ) {
                for (let block of loyaltySaleBlocks ) {
                    let defaultPrice = block.querySelector(".js-loyalty-sale-default").dataset.price;
                    let calcPriceBlock = block.querySelector(".js-loyalty-sale-calc");
                    let profitPriceBlock = block.querySelector(".js-loyalty-sale-profit");
                    let pricePriceBlock = block.querySelector(".js-loyalty-sale-price");

                    if ( defaultPrice && calcPriceBlock && profitPriceBlock ) {

                        let saleRate = sliderOne.dataset.sale;
                        let salePrice = sliderOne.dataset.price;

                        
                        let calcPrice = defaultPrice * (100 - +saleRate) / 100;
                        let profitPrice = +defaultPrice - +calcPrice;
                        
                        
                        calcPriceBlock.innerHTML = `${ calcPrice.toFixed(0) } руб.`;
                        profitPriceBlock.innerHTML = `${ profitPrice.toFixed(0) } руб.`;

                        if ( pricePriceBlock ) {
                            pricePriceBlock.innerHTML = `${ salePrice } руб.`;
                        }

                    }

                }
            }
        }

    }

    
}