export const yandexMap = () => {

    const shopsSeacrh = document.querySelector('.js-shops-search');
    const shopsBlocks = document.querySelectorAll('.js-shops-block');
    const shopsCount = document.querySelector('.js-shops-count');
    const shopsShow = document.querySelectorAll('.js-shops-show');
    const shopsFilters = document.querySelectorAll('[name=shops-map-filter]');
    const shopsFiltersMobile = document.querySelectorAll('[name=shops-map-filter-mobile]');
    const shopsMap = document.querySelector('.js-yandex-map');
    var shopsCoordinatesAll = [];
    var shopsCoordinatesDelivery = [];
    var shopsCoordinatesReturn = [];

    if ( shopsBlocks.length ) {
        for (let block of shopsBlocks ) {
            let coordinates = block.dataset.coordinates.split(",");
            shopsCoordinatesAll.push(coordinates);
    
            if ( block.dataset.type == 'delivery' ) {
                shopsCoordinatesDelivery.push(coordinates);
            }
            if ( block.dataset.type == 'return' ) {
                shopsCoordinatesReturn.push(coordinates);
            }
        }

        var count = shopsBlocks.length;
        shops_count(count);

    }


    if ( shopsSeacrh && shopsBlocks.length ) {
        shopsSeacrh.oninput = () => {

            //console.log('', shopsSeacrh.value.toLowerCase() );
            count = 0;
            
            for (let block of shopsBlocks ) {
                if ( block.dataset.search.toLowerCase().indexOf( shopsSeacrh.value.toLowerCase() ) != -1 ) {
                    block.classList.remove("display-none");
                    count = count + 1;
                } else {
                    block.classList.add("display-none");
                }
            }

            shops_count(count);

        }
    }

    function shops_count(val) {
        if ( shopsCount ){
            shopsCount.innerText = `Найдено ` + num2word(val, ["магазин", "магазина", "магазинов"]);
        }
    }

    function num2word(n, words) {
        return n + " " + (words[(n = (n = n % 100) > 19 ? (n % 10) : n) == 1 ? 0 : ((n > 1 && n <= 4) ? 1 : 2)]);
    }


    if ( shopsMap ) {
        ymaps.ready(function () {
        
            var myMap = new ymaps.Map('map', {
                    center: [55.751574, 37.573856],
                    zoom: 9,
                    behaviors: ['default', 'scrollZoom']
                }, {
                    searchControlProvider: 'yandex#search'
                }),
    
                    clusterer = new ymaps.Clusterer({
    
                    preset: 'islands#invertedVioletClusterIcons',
      
                    groupByCoordinates: false,
     
                    clusterDisableClickZoom: true,
                    clusterHideIconOnBalloonOpen: false,
                    geoObjectHideIconOnBalloonOpen: false
                }),
    
                    getPointData = function (index) {
                    return {
                        //balloonContentHeader: '<font size=3><b><a target="_blank" href="https://yandex.ru">Здесь может быть ваша ссылка</a></b></font>',
                        //balloonContentBody: '<p>Ваше имя: <input name="login"></p><p>Телефон в формате 2xxx-xxx:  <input></p><p><input type="submit" value="Отправить"></p>',
                        //balloonContentFooter: '<font size=1>Информация предоставлена: </font> балуном <strong>метки ' + index + '</strong>',
                        //clusterCaption: 'метка <strong>' + index + '</strong>'
                        
                        balloonContentHeader: "<font size=3>" + shopsBlocks[index]?.querySelector(".shops__block-title").innerHTML + "</font>",
                        balloonContentBody: "<div class='map-baloon-text'>" + shopsBlocks[index]?.querySelector(".shops__block-text").innerHTML + "</div>",
                        
                    };
                },
    
                    getPointOptions = function () {
                    return {
                        preset: 'islands#violetIcon'
                    };
                },
                points = shopsCoordinatesAll,
                geoObjects = [];
        
            
    
            for(var i = 0, len = points.length; i < len; i++) {
                geoObjects[i] = new ymaps.Placemark(points[i], getPointData(i), getPointOptions());
            }
    
            clusterer.options.set({
                gridSize: 80,
                clusterDisableClickZoom: true
            });
        
            clusterer.add(geoObjects);
            myMap.geoObjects.add(clusterer);
        
            myMap.setBounds(clusterer.getBounds(), {
                checkZoomRange: true,
                zoomMargin: [60,20]
            });
    
            function set_screen(coordinates) {
                myMap.setCenter(coordinates, 16);
            }
    
            if ( shopsShow.length ) {
                for (let show of shopsShow ) {
                    show.addEventListener('click',function(e){
                        e.preventDefault();
                        let coordinates = this.closest(".shops__block").dataset.coordinates.split(",");
                        console.log('', coordinates);
                        set_screen(coordinates);
                    });
                }
            }
    
            function shops_filtration(type) {
                let count = 0;
                if ( shopsBlocks.length ) {
                    for (let block of shopsBlocks ) {
                        if ( block.dataset.type == type ) {
                            block.classList.remove("shops__block_hidden");
                            count = count + 1;
                        } else {
                            block.classList.add("shops__block_hidden");
                        }
                        if ( type == 'all' ) {
                            block.classList.remove("shops__block_hidden");
                            count = shopsBlocks.length;
                        }
                    }
    
                    shops_count(count);
                }
            }
    
            if ( shopsFilters.length ) {
                for (let filter of shopsFilters ) {
                    filter.addEventListener('click',function(){
                        if ( filter.value == 'all' ) {
                            change_cluster(shopsCoordinatesAll);
                        }
                        if ( filter.value == 'delivery' ) {
                            change_cluster(shopsCoordinatesDelivery);
                        }
                        if ( filter.value == 'return' ) {
                            change_cluster(shopsCoordinatesReturn);
                        }
                        shops_filtration(filter.value);
                    });
                }
            }
    
            if ( shopsFiltersMobile.length ) {
                for (let filter of shopsFiltersMobile ) {
                    filter.addEventListener('click',function(){
                        if ( filter.value == 'all' ) {
                            change_cluster(shopsCoordinatesAll);
                        }
                        if ( filter.value == 'delivery' ) {
                            change_cluster(shopsCoordinatesDelivery);
                        }
                        if ( filter.value == 'return' ) {
                            change_cluster(shopsCoordinatesReturn);
                        }
                        shops_filtration(filter.value);
                    });
                }
            }
            
    
            function change_cluster(type){
                myMap.geoObjects.remove(clusterer);
                clusterer.removeAll();
    
                ymaps.clusterer = new ymaps.Clusterer({
    
                    preset: 'islands#invertedVioletClusterIcons',
      
                    groupByCoordinates: false,
     
                    clusterDisableClickZoom: true,
                    clusterHideIconOnBalloonOpen: false,
                    geoObjectHideIconOnBalloonOpen: false
                }),
    
                    getPointData = function (index) {
                    return {
                        balloonContentHeader: "<font size=3>" + shopsBlocks[index]?.querySelector(".shops__block-title").innerHTML + "</font>",
                        balloonContentBody: "<div class='map-baloon-text'>" + shopsBlocks[index]?.querySelector(".shops__block-text").innerHTML + "</div>",
                    };
                },
    
                    getPointOptions = function () {
                    return {
                        preset: 'islands#violetIcon'
                    };
                },
                points = type,
                geoObjects = [];
                
                for(var i = 0, len = points.length; i < len; i++) {
                    geoObjects[i] = new ymaps.Placemark(points[i], getPointData(i), getPointOptions());
                }
        
                clusterer.options.set({
                    gridSize: 80,
                    clusterDisableClickZoom: true
                });
            
                clusterer.add(geoObjects);
                myMap.geoObjects.add(clusterer);
            
                myMap.setBounds(clusterer.getBounds(), {
                    checkZoomRange: true,
                    zoomMargin: 70
                });
            }
    
        });
    }

    



    


   

}