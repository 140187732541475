export default function clearInput() {
  const forms = document.querySelectorAll("form");

  forms.forEach((form) => {
    // input.parentElement.classList.add("clear-field");
    fncToForm(form);
    form.addEventListener("input", () => {
      fncToForm(form);
    });
  });

  function fncToForm(form) {
    let blocks = form.querySelectorAll(".input-block");

    blocks.forEach((block) => {
      const input = block.querySelector(".input");

      if (input.value != "" || input.value.length > 0) {
        block.classList.add("clear-field");
      } else {
        block.classList.remove("clear-field");
      }
    });
  }

  forms.forEach((form) => {
    let blocks = form.querySelectorAll(".input-block");

    blocks.forEach((block) => {
      let clears = block.querySelectorAll(".input-close");
      clears.forEach((clear) => {
        clear.addEventListener("click", () => {
          let input = block.querySelector("input");

          input.value = null;
          //input.dispatchEvent(new Event('change'));

          fncToForm(form);
        });
      });
    });
  });
}
