export const certificateCheck = () => {

    
    const certificateCheck = document.querySelector('.js-certificate-check');
    const certificateCheckInput = document.querySelector('.js-certificate-check input');



    if ( certificateCheck ) {

        certificateCheck.addEventListener('submit', (e) => {
            e.preventDefault();
            let formData = new FormData(certificateCheck);
            let formAction = certificateCheck.getAttribute("action");
            fetch(formAction, {
                method: "POST",
                body: formData
            }).then(response => response.json()).then(response => {
                if (response.success) {
                    
                    let errors = certificateCheck.querySelectorAll(".input-block");
                    for (let error of errors ) {
                        error.classList.remove("error-field");
                    }

                    certificateCheck.querySelector(".modal__certificate-response")?.classList.remove("display-none");
                    //certificateCheck.querySelector(".modal__certificate-btn")?.removeAttribute("disabled");
                    
                    if ( certificateCheck.querySelector(".modal__certificate-balance") ) {
                        certificateCheck.querySelector(".modal__certificate-balance").innerHTML = response.sum;
                    }

                    certificateCheck.querySelector(".modal__certificate-btn_first")?.classList.add("display-none");
                    certificateCheck.querySelector(".modal__certificate-btn_second")?.classList.remove("display-none");

    
                    //alert( 'correct' )
    
                } else {
                    
                    let errors = certificateCheck.querySelectorAll(".input-block");
                    for (let error of errors ) {
                        error.classList.add("error-field");
                    }

                    certificateCheck.querySelector(".modal__certificate-response")?.classList.add("display-none");
                    //certificateCheck.querySelector(".modal__certificate-btn")?.setAttribute("disabled", true);

                    certificateCheck.querySelector(".modal__certificate-btn_first")?.classList.remove("display-none");
                    certificateCheck.querySelector(".modal__certificate-btn_second")?.classList.add("display-none");
                    
                    //alert( 'wrong' )
    
                }
            });
        });

        /* function certificate_check(e) {
            e.preventDefault();
            
        } */

        /* certificateCheck.addEventListener('submit', (e) => {
            certificate_check(e);
        }); */

        /* if ( certificateCheckInput ) {
            certificateCheckInput.oninput = (e) => {
                if ( certificateCheckInput.value.replaceAll("_","").length == 19 ) {
                    certificate_check(e);
                }
            }
        } */


    }
    

}