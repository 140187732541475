(function () {
  try {
    let forms = document.querySelectorAll("[data-valid-form]");

    forms.forEach((form) => {
      let inputs = form.querySelectorAll("input");
      let btn = form.querySelector("button");

      let inputsFilter = [...inputs].filter((filter) => filter.type != "file");

      function checkForm() {
        let localDisabled = [];

        inputsFilter.forEach((input) => {
          if (input.type == "tel") {
            let text = input.value;

            let shadowLength = text.match(/_/gim);
            // console.log(shadowLength);

            if (
              !shadowLength ||
              (!shadowLength?.length && input.value.length != 0)
            ) {
              localDisabled = [...localDisabled, false];
              input.parentElement.classList.remove("error-field");
            } else {
              localDisabled = [...localDisabled, true];
              input.parentElement.classList.add("error-field");
            }
          } else {
            if (input.value.length != 0) {
              localDisabled = [...localDisabled, false];
              // input.parentElement.classList.remove("error-field");
            } else {
              localDisabled = [...localDisabled, true];
              // input.parentElement.classList.add("error-field");
            }
          }
        });

        // btn.disabled = localDisabled.includes(true);
      }

      inputsFilter.forEach((input) => {
        input.addEventListener("input", () => {
          checkForm();
        });
        input.addEventListener("change", () => {
          checkForm();
        });
      });
    });
  } catch (e) {}
})();
