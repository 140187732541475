export const selects = () => {

    const selects = document.querySelectorAll(".js-select");  

    document.querySelector("[contenteditable=true]")?.addEventListener('keypress', (e) => {
        if (e.which === 13) {
            e.preventDefault();
        }
        /* if (e.keyCode == 8) {
            alert(1);
            if ( document.querySelector("[contenteditable=true]").innerText == '\n' ) {
            }
        } */
    });
    

    for (let select of selects ){
        let options = select.querySelectorAll(".select__option");
        let doubleOptions = select.querySelectorAll(".select__double-option");
        let labels = select.querySelectorAll(".select__label")
        let input = select.querySelector(".select__input");
        let value = select.querySelector(".select__value");
        let subValue = select.querySelector(".select__sub-value");
        let list = select.querySelector(".select__list");
        let clear = select.querySelector(".select__clear");

        let search = select.querySelector(".select-search");

        value.addEventListener("click",function(e){
            e.stopPropagation();
            console.log('val', );


            if ( e.target.parentElement.classList.contains("select_open") ) {
                document.querySelector(".select_open")?.classList.remove("select_open");
            } else {
                document.querySelector(".select_open")?.classList.remove("select_open");
                select.classList.add("select_open");
            }
            
        });

        if ( search ) {

            /* search.onfocus = () => {
                
            } */

            search.addEventListener('click',function(){
                search.innerText = '';
            });

            search.onblur = () => {
                if ( !select.querySelector('.select__option_selected') ) {
                    search.innerText = search.dataset.placeholder;
                } else {
                    search.innerText = select.querySelector('.select__option_selected').dataset.value;
                }
            }

            search.oninput = () => {

  
                //console.log('', search.innerText);
                if ( search.innerText == '\n' ) {
                    console.log('clear', );
                    for (let option of options ) {
                        option.classList.remove("display-none");
                    }
                } else {
                    for (let option of options ) {
                        if ( option.innerText.toLowerCase().indexOf( search.innerText.toLowerCase() ) != -1 ) {
                            option.classList.remove("display-none");
                        } else {
                            option.classList.add("display-none");
                        }
                    }
                }
                
            }

            for (let option of options ) {
                option.addEventListener('click',function(){
                    for (let option of options ) {
                        option.classList.remove("display-none");
                    }
                });
            }

        }



        
        
        if ( options.length ) {
            for (let option of options) {
                option.addEventListener("click",function(e){
                    select.querySelector('.select__option_selected')?.classList.remove("select__option_selected");
                    option.classList.add("select__option_selected");
    
                    input.value = option.dataset.value;
                    value.innerText = option.dataset.value;
                    //input.setAttribute("value", option.dataset.value);
                    input.setAttribute("data-selected", "");
                    value.setAttribute("data-selected", "");
                    select.classList.toggle("select_open");
    
                    input.setAttribute("data-full", "");
                    value.setAttribute("data-full", "");
                });
            }
        }

        if ( doubleOptions.length ) {
            for (let option of doubleOptions) {
                option.addEventListener("click",function(e){
                    select.querySelector('.select__option_selected')?.classList.remove("select__option_selected");
                    option.classList.add("select__option_selected");
    
                    input.value = option.dataset.value;
                    
                    value.innerHTML = `
                        <div class="t-primary-m-accent">${ option.dataset.value }</div>
                        <div class="t-primary-s select__sub-value">${ option.dataset.subvalue }</div>
                    `;

                    //input.setAttribute("value", option.dataset.value);
                    input.setAttribute("data-selected", "");
                    value.setAttribute("data-selected", "");
                    select.classList.toggle("select_open");
    
                    input.setAttribute("data-full", "");
                    value.setAttribute("data-full", "");
                });
            }
        }

        if ( labels.length ) {
            for (let label of labels ) {
                label.addEventListener('click',function(e){

                    if ( document.querySelector('.block-catalog__filters-clear') ) {
                        document.querySelector('.block-catalog__filters-clear').style.display = "flex";
                        document.querySelector('.block-catalog__filters-btn')?.classList.add("block-catalog__filters-btn_active");
                    }

                    let count = 0;
                    for (let i of labels ) {
                        if ( i.querySelector("input").checked ){
                            count = count + 1;
                        }
                    }
                    if ( count > 0 ) {
                        input.value = count;
                        value.innerText = value.dataset.placeholder + ` (${count})`;
                        input.setAttribute("data-full", "");
                        value.setAttribute("data-full", "");
                    } else {
                        input.value = count;
                        value.innerText = value.dataset.placeholder;
                        input.removeAttribute("data-full", "");
                        value.removeAttribute("data-full", "");
                    }
                });
            }
        }

        if ( clear ) {
            clear.addEventListener('click',function(){
                let checks = clear.parentElement.querySelectorAll("input");
                for (let check of checks ) {
                    check.checked = false;
                }
                input.value = 0;
                value.innerText = value.dataset.placeholder;
                input.removeAttribute("data-full", "");
                value.removeAttribute("data-full", "");
            });
        }
        
    }


    document.addEventListener("click",function(e){

        //console.log('', e.target.classList);

        //!e.target.classList.contains("select__value")
        //!e.target.classList.contains("select__label")
        if ( !e.target.closest(".select__value") && !e.target.closest(".select__label") && !e.target.closest(".select__clear") && !e.target.closest(".select__price") ) {
            document.querySelector(".select_open")?.classList.remove("select_open");
        }

    });



    


}