export const tabs = () => {

    const tabBtns = document.querySelectorAll('.js-tab-btn');
    const tabBoxes = document.querySelectorAll('.js-tab-box');
    

    if ( tabBtns.length && tabBoxes.length ) {
        for (let btn of tabBtns ) {
            btn.addEventListener('click',function(){
                let data = btn.dataset.tab;
                let frame = btn.closest(".tab-frame");

                if ( !frame ) {
                    frame = btn.closest("section");
                }

                frame.querySelector('.tab-btn-active')?.classList.remove("tab-btn-active");
                btn.classList.add("tab-btn-active");

                frame.querySelector('.tab-box-active')?.classList.remove("tab-box-active");
                frame.querySelector(`.js-tab-box[data-tab='${data}']`)?.classList.add("tab-box-active");
            });
        }
    }


}