export const certificate = () => {

    const certificateTarget = document.querySelector('.js-certificate-target');
    const takerBlocks = document.querySelectorAll('.certificate__block_taker');
    const certificateNomianal = document.querySelector('.js-certificate-nominal');
    const certificateNominalInput = document.querySelector('.js-certificate-nominal-input');


    if ( certificateTarget ) {
        let radios = certificateTarget.querySelectorAll("input");

        function blockStatus(status){
            if ( takerBlocks.length ) {
                takerBlocks.forEach( (e) => {
                    e.style.display = status;
                    e.querySelectorAll("input").forEach( (e) => {
                        if ( e.type != 'tel' ) {
                            if ( status == 'none' ) {
                                e.removeAttribute("required"); 
                            } else {
                                e.setAttribute("required", 'true'); 
                            }
                        }
                    } )
                } )
            }
        }

        for (let radio of radios ) {
            radio.addEventListener('click',function(){
                if ( radio.value == 'себе' ) {
                    blockStatus('none');
                } else {
                    blockStatus('flex');
                }
            });
        }

    }

    if ( certificateNomianal && certificateNominalInput ) {
        let radios = certificateNomianal.querySelectorAll("input");
        let input = certificateNominalInput;

        for (let radio of radios ) {
            radio.addEventListener('click',function(){
                let value = radio.value;
                certificateNominalInput.value = value;
            });
        }

        input.addEventListener('input',function(){
            input.value = input.value.replace("-", "");

            for (let radio of radios ) {
                radio.checked = false;
                if ( input.value == radio.value ) {
                    radio.checked = true;
                }
            }

            if ( +input.value < +input.min ) {
                input.parentElement.classList.add("error-field");
                input.nextElementSibling.innerHTML = input.nextElementSibling.dataset.errormin;

            } else if ( +input.value > +input.max ) {
                input.parentElement.classList.add("error-field");
                input.nextElementSibling.innerHTML = input.nextElementSibling.dataset.errormax;

            } else if ( +input.value % 100 > 0 ) {
                input.parentElement.classList.add("error-field");
                input.nextElementSibling.innerHTML = input.nextElementSibling.dataset.errorfraction;

            } else {
                input.parentElement.classList.remove("error-field");
            }

        });

    }


}

